import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './identities.scss';
import { ILimbusCharacter } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import lodash from 'lodash';
import { LimbusCharacter } from '../../modules/lc/common/components/lc-character';
import { LCSpecialitiesArray } from '../../modules/lc/model/lc-spec';

interface ILimbusCharacterNodes {
  nodes: ILimbusCharacter[];
}

interface ILimbusCharacterEntry {
  allCharacters: ILimbusCharacterNodes;
}

interface IProps {
  data: ILimbusCharacterEntry;
}

const LimbusAboutPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search identities...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '0', value: '0' },
          { label: '00', value: '00' },
          { label: '000', value: '000' }
        ]
      },
      {
        key: 'affinity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Envy',
            tooltip: 'Envy',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_envy.png"
                width={24}
                alt="Envy"
              />
            )
          },
          {
            value: 'Gloom',
            tooltip: 'Gloom',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_gloom.png"
                width={24}
                alt="Gloom"
              />
            )
          },
          {
            value: 'Gluttony',
            tooltip: 'Gluttony',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_gluttony.png"
                width={24}
                alt="Gluttony"
              />
            )
          },
          {
            value: 'Lust',
            tooltip: 'Lust',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_lust.png"
                width={24}
                alt="Lust"
              />
            )
          },
          {
            value: 'Pride',
            tooltip: 'Pride',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_pride.png"
                width={24}
                alt="Pride"
              />
            )
          },
          {
            value: 'Sloth',
            tooltip: 'Sloth',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_sloth.png"
                width={24}
                alt="Sloth"
              />
            )
          },
          {
            value: 'Wrath',
            tooltip: 'Wrath',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_wrath.png"
                width={24}
                alt="Wrath"
              />
            )
          }
        ]
      },
      {
        key: 'attack_type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Blunt',
            tooltip: 'Blunt',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_blunt.png"
                width={24}
                alt="Blunt"
              />
            )
          },
          {
            value: 'Pierce',
            tooltip: 'Pierce',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_pierce.png"
                width={24}
                alt="Pierce"
              />
            )
          },
          {
            value: 'Slash',
            tooltip: 'Slash',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_slash.png"
                width={24}
                alt="Slash"
              />
            )
          }
        ]
      },
      {
        key: 'sinner',
        type: 'dropdown',
        values: [
          { label: 'Any Sinner', value: 'all' },
          {
            label: 'Don Quixote',
            value: 'Don Quixote'
          },
          {
            label: 'Faust',
            value: 'Faust'
          },
          {
            label: 'Gregor',
            value: 'Gregor'
          },
          {
            label: 'Heathcliff',
            value: 'Heathcliff'
          },
          {
            label: 'Hong Lu',
            value: 'Hong Lu'
          },
          {
            label: 'Ishmael',
            value: 'Ishmael'
          },
          {
            label: 'Meursault',
            value: 'Meursault'
          },
          {
            label: 'Outis',
            value: 'Outis'
          },
          {
            label: 'Rodion',
            value: 'Rodion'
          },
          {
            label: 'Ryoshu',
            value: 'Ryoshu'
          },
          {
            label: 'Sinclair',
            value: 'Sinclair'
          },
          {
            label: 'Yi Sang',
            value: 'Yi Sang'
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: LCSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.attack_type && activeFilters.attack_type !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.attackType &&
            emp.attackType.length > 0 &&
            emp.attackType.indexOf(activeFilters.attack_type) > -1
        );
      }
      if (activeFilters.affinity && activeFilters.affinity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.affinity &&
            emp.affinity.length > 0 &&
            emp.affinity.indexOf(activeFilters.affinity) > -1
        );
      }
      if (activeFilters.sinner && activeFilters.sinner !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.baseCharacter.toLowerCase() ===
            activeFilters.sinner.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.speciality &&
            emp.speciality.length > 0 &&
            emp.speciality.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page limbus-characters'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>Identities</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/limbuscompany/categories/category_3.jpg"
            alt="Identities"
          />
        </div>
        <div className="page-details">
          <h1>Identities</h1>
          <h2>List of available Identities in Limbus Company.</h2>
          <p>
            Last updated: <strong>07/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Identities"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} identities
          </span>
        }
      />
      <div className="employees-filter-bar limbus limbus-id">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <LimbusCharacter
                  slug={emp.slug}
                  mode="card"
                  showLabel
                  showTags
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/limbuscompany/no_results.png"
            alt="No results"
          />
          <p>No identities found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default LimbusAboutPage;

export const Head: React.FC = () => (
  <Seo
    title="Identities | Limbus Company | Prydwen Institute"
    description="List of available Identities in Limbus Company."
    game="limbus"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulLimbusCharacters(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        name
        unitId
        slug
        rarity
        baseCharacter
        attackType
        speciality
        affinity
        isNew
        isReviewPending
      }
    }
  }
`;
